import pro1 from "../assests/project1.png";
import pro2 from "../assests/project2.png";
import pro3 from "../assests/project3.png";

const WorkCardData = [
  {
    imgsrc: pro1,
    title: "Instant Food App",
    description:
      "A Food Delivery Web Application, Where You can search for food and order. It is a Fullstack Application.",
    view: "url",
    source: "url",
  },
  {
    imgsrc: pro2,
    title: "Keeper App",
    description:
      "A Clone of Google Notes, Which Stores any Information that you like.",
    view: "url",
    source: "url",
  },
  {
    imgsrc: pro3,
    title: "Dev-signX",
    description:
      "Dev-signX is a single page website using HTML, CSS, Bootstrap and JavaScript.",
    view: "url",
    source: "url",
  },
];

export default WorkCardData;
